<template>
  <div class="pl-25">
    <div class="container">
      <router-link to="/facture-libre" class="custom-link">
        <span>
          Retourner vers Factures Libres
        </span>
      </router-link>
      <div class="upload-facture-validate" @click="uploadFacture">
        Télécharger facture
        <font-awesome-icon
          icon="arrow-alt-circle-down"
          class="icon-style-color mr-1"
          title="Télécharger la facture"
        />
      </div>
    </div>
    <iframe class="iframe-position" :src="src + '#toolbar=0'"></iframe>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'afficheFacture',
  data() {
    return {
      src: null
    };
  },
  methods: {
    ...mapActions(['displayFact', 'downloadPDF']),
    uploadFacture() {
      let ids = [];
      ids.push({
        id: this.$route.params.id
      });
      let bodyFormData = new FormData();
      bodyFormData.append('ids[0]', ids[0]['id']);
      this.downloadPDF({ id: ids[0].id });
    }
  },
  async mounted() {
    const response = await this.displayFact(this.$route.params.id);
    if (response.success === true) {
      this.src = response.response;
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-facture-validate {
  background-color: transparent !important;
  color: #4d4bac !important;
  font-weight: bold;
  border: 0px;
  font-size: 15px;
  box-shadow: unset;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  .icon-style-color {
    font-size: 18px;
    color: #4d4bac !important;
  }
}
.custom-link {
  color: #4d4bac;
  font-weight: bold;
}
.pl-25 {
  padding-left: 25%;
}
.container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.iframe-position {
  display: block;
  margin: auto;
  width: 750px;
  height: 92%;
  margin-top: 5px;
}
</style>
