<template>
  <iframe class="iframe-position" :src="src"></iframe>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      src: null
    };
  },
  methods: {
    ...mapActions(['displayFactureTemplate'])
  },
  async mounted() {
    let template = {
      id_template: this.$route.query.key.id_template,
      id_facture: this.$route.query.key.id_facture
    };
    const response = await this.displayFactureTemplate(template);
    if (response.success === true) {
      this.src = response.response;
    }
  }
};
</script>

<style lang="scss" scoped>
.iframe-position {
  display: block;
  margin: auto;
  width: 750px;
  height: 92%;
  margin-top: 5px;
}
</style>
